import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LTE from './page/LTE';
import NotFound from './page/NotFound';

const routing = (
    <Router>
        <Switch>
            <Route path = '/LTE' component= {LTE} />
            <Route component ={NotFound}/>
        </Switch>
    </Router>
)

ReactDOM.render(routing, document.getElementById('root'));
