import GxValidatePreauthorizedOperatorAccessRequest from '../../model/GxValidatePreauthorizedOperatorAccessRequest';

/**
 * Makes call to Galaxie API to validate access for a certain operator based on cellular factors (ip_address, mobile_address, platform)
 */
export default async function validatePreauthorizedOperatorAccess(galaxieApiUrl: string,
  request: GxValidatePreauthorizedOperatorAccessRequest): Promise<boolean> {
  try {
    // fetch to galaxie api
    const response = await fetch(`${galaxieApiUrl}/api/ValidatePreauthorizedOperatorAccess`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      throw new Error();
    }

    const obj = await response.json();

    let resp = obj.is_success || false;

    return resp;
  }
  catch (error) {
    console.error("An error occured while sending request for ValidatePreauthorizedOperatorAccess: ", error);
    return false;
  }
}
