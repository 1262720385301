import * as React from 'react';
import getOperator from '../service/galaxie/getOperator'
import validatePreauthorizedOperatorAccess from '../service/galaxie/validatePreauthorizedOperatorAccess';
import { RouteComponentProps } from 'react-router';
import generateToken from '../service/util/generateToken';
import GxGetOperatorRequest from '../model/GxGetOperatorRequest';
import GxValidatePreauthorizedOperatorAccessRequest from '../model/GxValidatePreauthorizedOperatorAccessRequest';
import Operator from '../model/Operator';
import '../style/LTE.css';
import { Button, Image, Alert } from 'react-bootstrap';
import GetParameters from '../service/util/getParameters';

interface PageState {
  operator: Operator
  ipAddress: string,
  platform: string,
  isSuccess: boolean,
  gxUrl: string,
  count: number,
  showAlert: boolean
}

class LTE extends React.Component<RouteComponentProps, PageState> {

  async componentDidMount() {
    this.getConfig()
      .then(res => this.setState({
        gxUrl: res["GALAXIE_API"]
      }))
      .then(res => this.initPageState())
      .catch(err => console.log(err));
  }

  initPageState = async () => {
    const values = GetParameters();

    let id, platform: string;
    id = values.operatorId ? values.operatorId : '28';
    platform = values.platform ? values.platform : 'WEB';

    this.setState({
      platform: String(platform),
      isSuccess: false,
      count: 0,
      showAlert: false
    });

    let operator = await this.getOperator(String(id));
    let ip = await this.getIp();

    this.setState({
      operator: operator,
      ipAddress: ip
    }, this.validatePreauthorizedOperatorAccess);

    return;
  }

  getIp = async () => {
    const response = await fetch('/api/ip');
    const body = await response.json();
    if (response.status !== 200) {
      throw Error(body.message)
    }
    return body;
  }

  getConfig = async () => {
    const response = await fetch('/config');
    const body = await response.json();
    if (response.status !== 200) {
      throw Error(body.message)
    }
    return body;
  }

  getOperator = async (operatorId: string) => {
    const request: GxGetOperatorRequest = {
      operator_id: operatorId,
      platform: "WEB",
      language_code: "en",
      data_option: [
        "OPERATOR_LOGO_WHITE_LARGE"
      ]
    }

    let operator = await getOperator(this.state.gxUrl, request);

    return operator;
  }

  handleContinue = async () => {
    this.getIp()
      .then(ip => this.setState({
        ipAddress: ip
      }, this.validatePreauthorizedOperatorAccess))
    document.body.focus();
  }

  validatePreauthorizedOperatorAccess = async () => {
    let request: GxValidatePreauthorizedOperatorAccessRequest = {
      operator_id: this.state.operator.id,
      platform: this.state.platform,
      ip_address: this.state.ipAddress
    }

    validatePreauthorizedOperatorAccess(this.state.gxUrl, request)
      .then((isSuccess) => {
        this.setState({
          isSuccess: isSuccess,
          showAlert: !isSuccess
        }, this.proceed);
      });
  }

  proceed = () => {
    if (this.state.isSuccess) {
      generateToken("IP_ADDRESS")
        .then(token => {
          window.location.replace(`stingraymusic://operator/${this.state.operator.id}?token=${token}`);
        })
        .catch(err => console.log(err));
    } else {
      this.setState({
        count: this.state.count + 1
      });
    }
  }

  render() {
    return (
      <div className="container">
        {
          this.state && this.state.operator && this.state.operator.logoUrl &&
          <div className="logo">{
            <Image fluid alt={this.state.operator.name} src={this.state.operator.logoUrl} />
          } </div>
        }
        <div className="steps">
          <p className="title"> Activate your account: </p>
          <div id="step-1">
            <p className="step-white">1. Turn <span className="step-blue">WiFi off</span>
              <Image className="icon" fluid src="icons/icon_wifi_off.png" />
            </p>
            <p className="info">
              Make sure you’re somewhere with good LTE, cellular service.
          </p>
          </div>
          {
            this.state && this.state.operator && this.state.operator.name &&
            <div id="step-2">
              <p className="step-white">2. Tap <span className="step-blue">Continue</span>
                <span> (below)</span>
              </p>
              <p className="info">
                Sit tight, it’ll take a minute to authenticate your {this.state.operator.name} service.
          </p>
            </div>
          }

          <div id="step-3">
            <p className="step-white">3. Turn <span className="step-blue">WiFi back on</span>
              <Image className="icon" fluid src="icons/icon_wifi_on.png" />
            </p>
            <p className="info">
              Once you’ve successfully authenticated please reconnect to WiFi for the best service.
          </p>
          </div>
        </div>

        <Button className="continue" variant="outline-light" onClick={this.handleContinue}>Continue</Button>

        {
          this.state && this.state.showAlert && this.state.count > 1 &&
          <Alert variant="danger" onClose={() => {
            this.setState({
              showAlert: false
            });
          }} dismissible>
            <p>Unable to detect the {this.state.operator.name} network.
              Please follow the instructions above and try again.</p>
          </Alert>
        }
      </div>
    );
  }
}

export default LTE;
