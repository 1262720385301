import GxGetOperatorRequest from '../../model/GxGetOperatorRequest';
import Operator from '../../model/Operator';

/**
 * Makes call to Galaxie API to get operator information 
 */
export default async function getOperator(galaxieApiUrl: string, request: GxGetOperatorRequest): Promise<Operator> {
    try {
        // fetch to galaxie api
        const response = await fetch(`${galaxieApiUrl}/api/GetOperator`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(request)
        });

        if (!response.ok) {
            throw new Error();
        }

        const obj = await response.json();

        let operator: Operator = {
            id: obj.operator.id,
            name: obj.operator.name,
            logoUrl: obj.operator.logo[0].uri
        };

        return operator;
    }
    catch (error) {
        console.error("An error occured while sending request for GetOperatorRequest: ", error);
        return { id: "", name: "", logoUrl: "" };
    }
}
